<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl fluid>
            <div>
                <users-data-table
                :settings="dataTableSettings"
                :queryParameters="queryParameters"
                ></users-data-table>   

                <data-table-toggle-columns
                :dataTableId="dataTableSettings.id"
                :columns="dataTableSettings.headers"
                :dataTableColumnsMenuId="dataTableSettings.eventBus.toggleColumnsId"

                ></data-table-toggle-columns>
            </div>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate } from '@/helpers/utilities'
const UsersDataTable = () => import ('@/components/admin/UsersDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')

export default {
    components: {
        UsersDataTable,
        DataTableToggleColumns
    },
    data: () => ({
        dataTableSettings: {
            id: 'dtUsers',
            title: 'Seznam uporabnikov',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", align: "left", visibility: true},
                { text: "Naziv", value: "name", visibility: true},
                { text: "Email", value: "email", visibility: true},
                { text: "Vloge v čakanju", value: "applications_status_waiting", visibility: true, sortable: false},
                { text: "Aktivne dovolilnice", value: "permits_status_active", visibility: true, sortable: false},                                                                
                { text: "Čas registracije", value: "created_at", visibility: true},
                { text: "Čas zadnje prijave", value: "last_seen", visibility: true},
                { text: "Upravljanje", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: 'v1/admin/users',
            editItem: {
                route: 'admin_users_edit',
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: 'DELETE_USER',
                replaceStrWithColumnValue: 'name',
                confirmationText : "Sta prepričani da želite odstraniti uporabnika {str_to_replace}? Odstranjene bodo tudi vse uporabniku pripadajoče vloge in dovolilnice.",
                dialogConfirmSuccessText: 'Uporabnik {str_to_replace} je bil uspešno odstranjen.',
                dialogConfirmFailText: 'Pri odstranjevanju uporabnika {str_to_replace} je prišlo do napake. Poskusite ponovno.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_users',
                totalItems : {
                    event: ''
                },
                search: 'dt-users-search',
                toggleColumnsId: 'toggle-users-list-columns'
            }


        }
    }),

    computed: {
        queryParameters() {
            return {
            }
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
    }
}

</script>

<style scoped>

</style>